<template>
	<div class="mobPage">
		<div class="head-bar-wrap-142ff ">
			<router-link class="head-bar-logo-4dab1 logo-wrap" to="/index">
				<img src="../assets/logo.png" />
			</router-link>
		</div>
		<div class="page-wrap">
			<div class="right_wrap  wap-wrap">
				<div class="bce-login-type-tab">
					<div class="passport-tab-btn active last-login">中文.官媒账号</div>
				</div>
				<div class="bce-passport-wrap ">
					<div id="pass-login" class="tang-pass-login compose-style">
						
						<div id="componseRight" class="compose-right-container">
							<div id="TANGRAM__PSP_4__sms" class="tang-pass-login tang-pass-sms "
								style="display: block; visibility: visible; opacity: 1;" v-if="loginType=='0'">
								<!-- <p class="tang-pass-sms-tip">验证即登录，未注册将自动创建百度账号</p> -->
								<div id="TANGRAM__PSP_4__smsForm">
									
									<div id="TANGRAM__PSP_4__smsPhoneWrapper"
										class="pass-form-item pass-form-item-smsPhone"><input
											id="TANGRAM__PSP_4__smsPhone" type="text" name="username"
											class="pass-text-input pass-text-input-smsPhone" v-model="phone" placeholder="请输入手机号"></div>
									<p id="TANGRAM__PSP_4__smsVerifyCodeWrapper"
										class="pass-form-item pass-form-item-smsVerifyCode"><input
											id="TANGRAM__PSP_4__smsVerifyCode" type="text" name="password"
											class="pass-text-input pass-text-input-smsVerifyCode" v-model="code" maxlength="6"
											placeholder="短信验证码">
											<button id="TANGRAM__PSP_4__smsTimer"class="pass-item-timer" @click="sendSms" :disabled="dis">
												{{ second === totalSecond ? '发送验证码' : second + `秒后重试`}}
												<!-- 发送验证码 -->
											</button>
									</p>
									
									<!-- <p id="TANGRAM__PSP_4__smsErrorWrapper" class="pass-generalErrorWrapper">
										<span id="TANGRAM__PSP_4__smsError" class="pass-generalError">请填写手机号</span>
									</p> -->
									
									<p id="TANGRAM__PSP_4__smsSubmitWrapper"
										class="pass-form-item pass-form-item-submit"><input
											id="TANGRAM__PSP_4__smsSubmit" type="submit" value="登录/注册" @click="login"
											class="pass-button pass-button-submit"></p>
									
								</div>
							</div>
							<div id="TANGRAM__PSP_4__form" class="pass-form pass-form-normal" v-if="loginType=='1'"
								autocomplete="off" style="">
								
								
								<p id="TANGRAM__PSP_4__userNameWrapper" class="pass-form-item pass-form-item-userName"
									style="display:">
									<input
										id="TANGRAM__PSP_4__userName" type="text" name="userName"
										class="pass-text-input pass-text-input-userName" autocomplete="off" value="" v-model="phone"
										placeholder="请输入手机号">
								</p>
								<p id="TANGRAM__PSP_4__passwordWrapper" class="pass-form-item pass-form-item-password"
									style="display:"><input
										id="TANGRAM__PSP_4__password" type="password" name="password"
										class="pass-text-input pass-text-input-password" autocomplete="off" value="" v-model="password"
										placeholder="请输入密码">
								</p>
								
								<p id="TANGRAM__PSP_4__submitWrapper" class="pass-form-item pass-form-item-submit">
									<input id="TANGRAM__PSP_4__submit" type="submit" value="登录" @click="login"
										class="pass-button pass-button-submit"></p>
							</div>
							<p id="TANGRAM__PSP_4__headerLoginTab" class="login-type-tab">
								<span id=" TANGRAM__PSP_4__changePwdCodeItem" class="switch-item" v-if="loginType=='0'" @click="loginType='1'">账号登录</span>
								<span id="TANGRAM__PSP_4__changeSmsCodeItem" class="switch-item" v-if="loginType=='1'" @click="loginType='0'">短信登录</span>
							</p>
							<a href="https://view.officeapps.live.com/op/embed.aspx?src=https://admin.iptzx.com/specification.doc" target="_blank">中文.官媒注册规范</a>
							
						</div>
					</div>
					<!-- <div class="tang-pass-footerBar"><a class="pass-reglink pass-link" id="TANGRAM__PSP_4__regLink"
							style="display:none">立即注册</a></div>
					 -->
					<div id="agreemet-foot-bar">
						<span class="tang-pass-sms-agreement pass-link">
							我已阅读并同意<a	target="_blank" rel="noreferrer" href="https://admin.iptzx.com/policy/service.html">用户协议</a>
							和<a target="_blank" rel="noreferrer" href="https://admin.iptzx.com/policy/privacy.html">隐私政策</a></span>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import { message } from 'ant-design-vue'
	export default{
		data() {
			return {
				loginItem: ['短信登录', '密码登录'],
				loginType: '0',
				phone: '',
				password: '',
				code: '',
				dxTip: '',
				agree: false,
				totalSecond: 60, // 总秒数
				second: 60, // 倒计时的秒数
				timer: null, // 定时器 id
				showPage: 'pc',
				dis:false
			}
		},
		mounted() {
			this.isMobile()
		},
		methods: {
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				return flag;
			},
			async sendSms() {
		
				let that = this
				console.log(that.phone)
				if (that.phone.trim() == '') {
					// that.dxTip = '请输入手机号'
					message.error('请输入手机号')
					return
				}
				that.dxTip = ''
				const res = await that.request({
					method: "post",
					url: "https://admin.iptzx.com/api/sms/sendCode",
					data: {
						mobile: that.phone,
						scene: 'YZMDL'
					}
				})
				console.log(res)
				if (res.data.code == 1) {
					message.info('验证码发送成功')
					that.getCode()
					this.dis=true
				}
			},
			async getCode() {
				
				// 当前目前没有定时器开着，且 totalSecond 和 second 一致 (秒数归位) 才可以倒计时
				if (!this.timer && this.second === this.totalSecond) {
					// 开启倒计时
					this.timer = setInterval(() => {
						this.second--
		
						if (this.second <= 0) {
							clearInterval(this.timer)
							this.timer = null // 重置定时器 id
							this.second = this.totalSecond // 归位
							this.dis=false
						}
					}, 1000)
		
					// 发送请求，获取验证码
					// this.$toast('发送成功，请注意查收')
					// alert('发送成功，请注意查收')
				}
			},
			async login() {
				let that = this
				var type
				if (this.loginType == 0) {
					//验证码登录
					type = 2
					if (this.phone.trim() == '') {
						message.error('请输入手机号')
						return
					}
					if (this.code.trim() == '') {
						message.error('请输入验证码')
						return
					}
		
				} else if (this.loginType == 1) {
					//密码登录
					type = 1
					if (this.phone.trim() == '') {
						message.error('请输入手机号')
						return
					}
					if (this.password.trim() == '') {
						message.error('请输入密码')
						return
					}
				}
				const res = await this.request({
					method: "post",
					url: "https://admin.iptzx.com/api/login/account",
					data: {
						account: this.phone,
						terminal: '4',
						scene: type,
						password: this.password,
						code: this.code
					}
				})
				console.log(res)
				if (res.data.code == 1) {
					localStorage.setItem('avatar', res.data.data.avatar)
					localStorage.setItem('nickname', res.data.data.nickname)
					localStorage.setItem('token', res.data.data.token)
					message.success('登录成功')
					setTimeout(function(){
						that.$router.push("/index")
					},1500)
				}
				if (res.data.code == 0 && res.data.show == 1) {
					console.log(res.data.msg)
				}
			}
		
		}
	}
</script>

<style scoped>
	@import url('../style/mlogin.css');
</style>