<template>
	<div class="pyrPage">
		<header-top></header-top>
		<div id="app-root" class="solution-container solution-zxlcopyright">
			<div class="rno-3-hero-group rno-3-hero-group-index adjust" id="banner">
				<div class="rno-3-hero">
					<div class="rno-3-hero-list">
						<div class="rno-3-hero-item"
							data-srcset="https://main.qcloudimg.com/raw/1a7dc3523dbf06a61cac0bc8dde13ef2.jpg pc pad, https://main.qcloudimg.com/raw/283ad441e8b9b58806491bfcca43d045.jpg m"
							style="background-image: url(&quot;https://main.qcloudimg.com/raw/1a7dc3523dbf06a61cac0bc8dde13ef2.jpg&quot;);">

							<div class="rno-3-hero-bg-color"></div>
							<div class="rno-3-hero-embellish"
								style="background-image: url(https://main.qcloudimg.com/raw/b68e5f1d3dacfcb3d6d734ca3f447d76/mid-img.png)">
							</div>

							<div class="rno-3-hero-item-inner">

								<div class="rno-3-hero-main-pic"
									style="background-image: url(https://main.qcloudimg.com/raw/1cf18a50384ce1be3ac308f04284cefe.png);">
								</div>


								<div class="rno-3-hero-item-ctn">

									<h1 class="rno-3-hero-item-title">企业官媒版权保护</h1>
									<div class="rno-3-hero-item-desc rno-pad-hide">
										<p>中文.官媒版权确权，侵权监测，侵权取证，调解维权综合解决方案</p>
									</div>
									<div class="rno-3-hero-item-desc rno-pc-hide rno-pad-block">
										<p>中文.官媒版权确权，侵权监测，侵权取证，调解维权综合解决方案</p>
									</div>
								</div>
								<div class="rno-3-hero-item-btns rno-3-hero-item-list-1">



									<!-- <a hotrep="solution.zxlcopyright.banner.btn1"
										class="rno-btn rno-btn-primary rno-btn-l"
										href="https://cloud.tencent.com/apply/p/n74hoekq6e" target="_blank">
										<span class="rno-btn-text">立即申请</span>
									</a> -->



								</div>
							</div>


						</div>
					</div>
				</div>
			</div>
			<section class="rno-section" id="fajj">
				<div class="rno-section-inner">
					<div class="rno-section-hd">
						<h3 class="rno-section-tit">方案简介</h3>
					</div>
					<div class="rno-section-bd">


						<div class="rno-2-media-section">
							<div class="rno-content-layout-2">
								<div class="rno-content-layout-main">
									<div class="rno-combo">
										<div class="rno-draft">
											<p style="width: 500px;font-size: 16px;color: #333;line-height: 32px;">
												中文.企业官媒标识版权保护解决方案提供全生命周期的解决方案，通过“官媒标识库”能够快速固化版权权属信息，降低版权确权的时间成本与经济成本。权属确定后可启动版权侵权监测，一旦发现侵权行为，便对侵权行为取证形成可靠电子证据，再将电子证据存证到“官媒标识库”。有了明确的权属信息、可信的侵权证据，权属方便可快速的通过版权调解或诉讼服务维护自身权益，以此形成版权保护全生命周期的服务闭环。
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="rno-section bg-color rno-section-bg-shading" data-type="grid" data-action="" id="fays">
				<div class="rno-section-inner">
					<div class="rno-section-hd">
						<h3 class="rno-section-tit">方案优势</h3>
					</div>
					<div class="rno-section-bd">
						<div class="rno-lattice rno-lattice-cols-4 rno-lattice-cols-m-2 rno-lattice-cols-s-1 rno-lattice-without-margin">
							<div class="rno-lattice-cell">
								<div class="rno-1-media-panel rno-scheme-section rno-1-media-panel-left rno-1-media-panel-m-horizontal">
									<div class="rno-1-media-panel-object"><img class="rno-1-media-panel-icon"
											src="https://main.qcloudimg.com/raw/b7d13517db2683ea9529ff82d1e7f573.svg">
									</div>
									<div class="rno-1-media-panel-body">
										<h4 class="rno-1-media-panel-title">司法认可</h4>
										<p class="rno-1-media-panel-desc ">
											官媒标识版权保护系统所采流程与方法，精准契合最高院及司法部证据采信标准，平台业已积累诸多司法判例，充分彰显其司法实践效能。该系统可与 300 余家法院顺利实现在线核验对接，所涉证据能够经由 “微法院”“诉讼服务网”“至信 E 站” 等权威渠道径直提交，为版权保护司法工作构筑起坚实高效的运行架构，有力维护版权相关方合法权益，显著促进版权保护法治生态的健康有序构建与完善发展。
										</p>
									</div>
								</div>
							</div>

							<div class="rno-lattice-cell">
								<div
									class="rno-1-media-panel rno-scheme-section rno-1-media-panel-left rno-1-media-panel-m-horizontal">
									<div class="rno-1-media-panel-object"><img class="rno-1-media-panel-icon"
											src="https://main.qcloudimg.com/raw/bb223f9f61ec48a1fbcc54f78f49f610.svg">
									</div>
									<div class="rno-1-media-panel-body">
										<h4 class="rno-1-media-panel-title">强公信力</h4>
										<p class="rno-1-media-panel-desc ">
											中国版权协会、国家工业信息安全中心、新华网以及多家公证处等近 40 家在行业内极具影响力与权威性的机构，已正式加入官媒标识生态体系之中。官媒标识凭借其卓越的品质与规范性，成功通过了由多方专业机构开展的多项权威测评，充分彰显出其在技术先进性、标准合规性以及安全可靠性等多方面的突出优势，进一步巩固了其在行业内的领先地位与公信力，为构建更加完善、有序且富有活力的媒体标识与版权保护生态格局奠定了坚实而广泛的基础，有力推动了整个行业在相关领域的高质量发展与规范化进程。
										</p>
									</div>
								</div>
							</div>

							<div class="rno-lattice-cell">
								<div
									class="rno-1-media-panel rno-scheme-section rno-1-media-panel-left rno-1-media-panel-m-horizontal">
									<div class="rno-1-media-panel-object"><img class="rno-1-media-panel-icon"
											src="https://main.qcloudimg.com/raw/258c89682c8202ab2563cd4ee142bd29.svg">
									</div>
									<div class="rno-1-media-panel-body">
										<h4 class="rno-1-media-panel-title">丰富经验</h4>
										<p class="rno-1-media-panel-desc ">
											腾讯游戏、腾讯企鹅号、原创馆、得到、贝壳找房、阅文集团、爱奇艺、品推资讯（企业官媒标识，顶级中文.官媒）等一众行业知名品牌，均已率先开启对同类型版权保护服务的广泛应用进程，通过深度运用此类服务，得以成功攻克海量作品在知识产权保护领域所面临的诸多复杂难题，为行业在知产保护方面树立起典范标杆，有力推动了整个行业对于版权保护重视程度的提升以及相关实践工作的有效开展，在维护自身品牌知产权益与促进文化创意产业健康发展的道路上迈出了坚实且意义深远的步伐。

										</p>
									</div>
								</div>
							</div>

							<div class="rno-lattice-cell">
								<div
									class="rno-1-media-panel rno-scheme-section rno-1-media-panel-left rno-1-media-panel-m-horizontal">
									<div class="rno-1-media-panel-object"><img class="rno-1-media-panel-icon"
											src="https://main.qcloudimg.com/raw/871974a5b199388ea812f24b298ca65f.svg">
									</div>
									<div class="rno-1-media-panel-body">
										<h4 class="rno-1-media-panel-title">使用便捷</h4>
										<p class="rno-1-media-panel-desc ">
											官媒标识全面支持网页服务模式，能够高效实现版权存证、确权、监测以及维权等一站式全方位服务功能。其独特的服务设计，无需复杂的流程，即可便捷使用相关服务。通过这一模式，为用户构建起一个简洁、高效且极具专业性的版权服务，极大地提升了版权管理与保护的效率与便捷性，充分满足了用户在版权事务处理中的多元需求，有力推动了版权服务领域的数字化进程与规范化发展。
										</p>
									</div>
								</div>
							</div>

						</div>



					</div>
				</div>
				<i class="rno-shading-1-left"></i>
				<i class="rno-shading-1-right"></i>
			</section>
			<section class="rno-section" id="fajg">
				<div class="rno-section-inner">
					<div class="rno-section-hd">
						<h3 class="rno-section-tit">方案架构</h3>
					</div>
					<div class="rno-section-bd">


						<div class="rno-content-layout-1">
							<div class="rno-content-layout-main">

								<img class="rno-tabs-simple-img-view"
									src="../assets/copyright/lct.png">

							</div>
							<div class="rno-content-layout-side">
								<div class="rno-combo">



									<div class="rno-draft">
										
										
										<p>内容平台与“官媒标识库”对接，创作完成时，存证既固定权属信息。</p>
										<p>版权作品存证后也可以由作者自行选择借助“官媒标识库”进行登记等操作。还可以利用“官媒标识”所提供的版权监测服务，全网全品类全时段地对版权作品进行侵权监测，当发生侵权行为时，可以使用“官媒标识”所提供的版权取证能力固定侵权内容。后续当作者诉诸于法律维权时，可以将已固定的权属信息及侵权内容作为证据通过最高人民法院与腾讯联合建设的“微法院”诉讼平台小程序等通道直接提交至法院，也可通过“至信E站”在广州互联网法院发起纠纷调解。
										</p>
										
									</div>



								</div>
							</div>
						</div>


					</div>
				</div>
			</section>
			<section class="rno-section bg-color rno-section-bg-shading" data-type="grid" data-action="" id="khal">
				<div class="rno-section-inner">
					<div class="rno-section-hd">
						<h3 class="rno-section-tit">客户案例</h3>
					</div>
					<div class="rno-section-bd">


						<div
							class="rno-lattice-card rno-lattice-cols-4 rno-lattice-cols-m-2 rno-lattice-cols-s-1 rno-lattice-without-margin">

							<div class="rno-lattice-cell">
								<div class="rno-1-logo-case" style="height: initial;">
									<picture>
										<source
											srcset="https://main.qcloudimg.com/raw/e74b3ecc62cadcf45f146bfc21ce388b.png"
											media="(max-width: 768px)">
										<img src="https://main.qcloudimg.com/raw/b4d437d7e8cd0f50953dec2c29ced8c0.png"
											alt="">
									</picture>
								</div>
							</div>

							<div class="rno-lattice-cell">
								<div class="rno-1-logo-case" style="height: initial;">
									<picture>
										<source
											srcset="https://main.qcloudimg.com/raw/a8fd27aa2da6fc02d986127ac5e13498.png"
											media="(max-width: 768px)">
										<img src="https://main.qcloudimg.com/raw/bc2c9cc02eb20eb9abd97fff51ad5713.png"
											alt="">
									</picture>
								</div>
							</div>

							<div class="rno-lattice-cell">
								<div class="rno-1-logo-case" style="height: initial;">
									<picture>
										<source
											srcset="https://main.qcloudimg.com/raw/954ed4818bde5ebfc40616f735f47425.png"
											media="(max-width: 768px)">
										<img src="https://main.qcloudimg.com/raw/c5d8bb318e495cb03fff079b7ce9f45c.png"
											alt="">
									</picture>
								</div>
							</div>

							<div class="rno-lattice-cell">
								<div class="rno-1-logo-case" style="height: initial;">
									<picture>
										<source
											srcset="https://main.qcloudimg.com/raw/4765a120b80f11b9e05d4877e14aec2d.png"
											media="(max-width: 768px)">
										<img src="https://main.qcloudimg.com/raw/210a222cb1437001ee47ab43eafa6def.png"
											alt="">
									</picture>
								</div>
							</div>

							<div class="rno-lattice-cell">
								<div class="rno-1-logo-case" style="height: initial;">
									<picture>
										<source
											srcset="https://main.qcloudimg.com/raw/b4820d41989926710a88e3025d5f0bbe.png"
											media="(max-width: 768px)">
										<img src="https://main.qcloudimg.com/raw/e73fb0ff5a69eaf319442e299665ba50.png"
											alt="">
									</picture>
								</div>
							</div>

							<div class="rno-lattice-cell">
								<div class="rno-1-logo-case" style="height: initial;">
									<picture>
										<source
											srcset="https://main.qcloudimg.com/raw/a9a4c13898d662063bc8065325b85466.png"
											media="(max-width: 768px)">
										<img src="https://main.qcloudimg.com/raw/239ffe8bf20f664f61d4a7fdbe320f9f.png"
											alt="">
									</picture>
								</div>
							</div>

							<div class="rno-lattice-cell">
								<div class="rno-1-logo-case" style="height: initial;">
									<picture>
										<source
											srcset="https://main.qcloudimg.com/raw/005bd8ca3c1fae572fcf8b19e19bc9dd.png"
											media="(max-width: 768px)">
										<img src="https://main.qcloudimg.com/raw/648d92c8607871fb8a0ea508d9be0ac1.png"
											alt="">
									</picture>
								</div>
							</div>

							<div class="rno-lattice-cell">
								<div class="rno-1-logo-case" style="height: initial;">
									<picture>
										<source
											srcset="https://main.qcloudimg.com/raw/377319599ae58081194ec7d56b0f8c26.png"
											media="(max-width: 768px)">
										<img src="https://main.qcloudimg.com/raw/a063bafd02f8f747cf7dab3c36bf2277.png"
											alt="">
									</picture>
								</div>
							</div>

						</div>



					</div>
				</div>
				<i class="rno-shading-2-left"></i>
				<i class="rno-shading-2-right"></i>
			</section>
			
		</div>
		<transition name="fade">
			<div class="mask" v-if="menuShow=='1'" @click="menuShow=0"></div>
		</transition>
		<!-- 底部导航 -->
		<footer class="cloud-footer footer-pc-container">
			<div class="footer-pc">
				<div class="copy-right">
					<p style="text-align: center;">
						<a class="space" target="_blank" href="">© 2024 使用中文·官媒前必读</a>
						<!-- <span class="space">增值电信业务经营许可证：B1.B2-20100266</span> -->
						<a class="space" :href="icpUrl">{{icp}}</a>
						<!-- <a class="space" target="_blank">官媒注册服务机构许可：京D3-20210001</a> -->
						<!-- <span class="space">代理官媒注册服务机构：商务中国 新网数码 江苏邦宁 三五互联</span> -->
						<a class="space" target="_blank" :href="wabUrl" rel="noreferrer">{{wab}}</a>
						<a target="_blank" href="https://admin.iptzx.com/policy/privacy.html">隐私政策</a>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	import headerTop from '../components/headerTop.vue'
	export default {
		data() {
			return {
				token:'',
				headImg: '',
				userName: '',
				icp: '',
				icpUrl: '',
				wab: '',
				wabUrl: '',
				ysUrl: '',
				loginUrl:'login',
				menuShow:'0'
			}
		},
		components:{
			headerTop
		},
		mounted() {
			// this.getSs('', 1)
			this.icp = localStorage.getItem('icp')
			this.icpUrl = localStorage.getItem('icpUrl')
			this.wab = localStorage.getItem('wab')
			this.wabUrl = localStorage.getItem('wabUrl')
			if (localStorage.getItem('token')) {
				this.token = localStorage.getItem('token')
				this.userName = localStorage.getItem('nickname')
				this.headImg = localStorage.getItem('avatar')
			}
			// this.isMobile()
		}
	}
</script>

<style scoped>
	@import url('../style/header.css');
	@import url('../style/copyright.css');
	@import url('../style/footer.css');
	.userName {
		font-size: 14px !important;
		margin-right: 20px;
	}
	#fajj
	{
		/* background: url(../assets/copyright/jjBg1.png) no-repeat center; */
		/* background-size: 1920px 520px; */
		/* background-position: 200px; */
		background: linear-gradient(to right, rgb(211, 224, 245), rgb(211, 224, 245) 50%, rgba(222, 234, 250, 0)) left center / calc(100% - 1920px) 100% no-repeat, linear-gradient(to left, rgb(213, 226, 246), rgb(213, 226, 246) 50%, rgba(217, 227, 246, 0)) right center / calc(100% - 1920px) 100% no-repeat, url(../assets/copyright/jjBg1.png) center center / 1920px 520px no-repeat;
		height: 500px;
	}
	.mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 8;
	}
</style>