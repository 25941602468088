<template>
	<div class="cooPage">
		<div class="preview" id="preview" style="transform: none; transform-origin: left top; width: 100%;">

			<header-top></header-top>
			<div id="a7955a96a8a45fa" section-id="a7955a96a8a45fa"
				class="section uk-section uk-padding-remove-vertical uk-dark free"
				style=";height:392px;overflow:hidden;">
				<div class="ap-background-container uk-cover-container" style="
		                    position: absolute">
					<div class="ap-background-layer" style="background-color: undefined"></div>
					<div class="ap-background-layer"
						:style="
		                        'opacity: 1;background-position: center center;background-repeat: no-repeat;background-size: cover;background-image: url(' + require('@/assets/cooperate/1650769350039.jpeg')+');background-attachment: auto;'">
					</div>

				</div>
				<div class="free-content-wrap" style="max-width: 1200px; margin-left: -600px">
					<div id="a2d8ba1ecea654e" class="box"
						style="left:0px;top:50px;width:789px;min-height:173px;position:absolute;height:173px">
						<div class="ele-wrap normal">
							<h3 id="ad464a92f5ac322" class="text no-margin nowrap"
								style="; left:0px;top:83px;width:536px;min-height:50px;position:absolute;height:50px">
								<div style="text-shadow: none;"><span
										style="font-size: 36px; color: rgb(255, 255, 255);"><strong>助推“版权保护解决方案”，携手共赢未来</strong></span>
								</div>
							</h3>
							<h3 id="a1ab9a3e18ac64d" class="text no-margin nowrap"
								style="; left:0px;top:162px;width:720px;min-height:36px;position:absolute;height:36px">
								<div style="text-shadow: none;"><span
										style="font-size: 26px; color: rgb(255, 255, 255);">版权保护全生命周期服务合作伙伴招募中，诚邀您加入我们!</span>
								</div>
							</h3>
						</div>
					</div>
				</div>
				<div class="ap-content-container"> </div>
			</div>
			<div id="a95b6a38d4a5f09" section-id="a95b6a38d4a5f09"
				class="section uk-section uk-section-small uk-dark free"
				style=";height:569px;overflow:hidden;background: rgba(244,249,255,1);">
				<div class="ap-background-container uk-cover-container" style="background: rgba(244,249,255,1)">

				</div>
				<div class="free-content-wrap" style="max-width: 1200px; margin-left: -600px">
					<h2 id="ae338ab6d0a893f" class="text no-margin nowrap"
						style="; left:460px;top:60px;width:280px;min-height:41px;position:absolute;height:41px">
						<div style="text-shadow: none; text-align: center;"><span
								style="font-size: 28px; color: rgb(25, 28, 61);"><strong>我们的产品矩阵</strong></span></div>
					</h2>
					<!-- left156 -->
					<div id="a7a43af689a2726" class="box h-float"
						style="left:316px;top:166px;width:262px;min-height:320px;position:absolute;height:320px">
						<div class="ele-wrap normal">
							<div id="adea0aebe6a2d3c" class="image"
								style="left:43px;top:26px;width:175px;min-height:122px;position:absolute;height:122px">

								<router-link to="copyright" class="img-wrap " target="_blank">
									<img src="../assets/gmLogo.png" style="height: 100%;width: auto;">
								</router-link>
							</div>
							<h3 id="a4a39af248a2da4" class="text no-margin nowrap cpTitle"
								style="; left:51px;top:160px;width:158px;min-height:33px;position:absolute;height:33px">
								<div style="text-shadow: none; text-align: center;">
									<router-link to="copyright" target="_blank">
										<span style="font-size: 18px; color: rgb(25, 28, 61);">
											<strong>中文.官媒</strong>
										</span>
									</router-link>
								</div>
							</h3>
							<div id="aea1baf40ea1f26" class="text no-margin cpContent"
								style="; left:17px;top:203px;width:226px;min-height:84px;position:absolute;height:84px">
								<div style="text-align: center; text-shadow: none;"><a href="" rel="noopener noreferrer"
										target="_blank"><span
											style="font-size: 14px; color: rgb(117, 119, 139);">中文.官媒版权确权，侵权监测，侵权取证，调解维权综合解决方案</span></a>
								</div>
							</div>
						</div>
					</div>
					<!-- left:468 -->
					<div id="a0eb9ae6fda1051" class="box h-float"
						style="left:624px;top:166px;width:262px;min-height:320px;position:absolute;height:320px">
						<div class="ele-wrap normal">
							<div id="a9da2a1e8badd7b" class="image"
								style="left:45px;top:26px;width:171px;min-height:114px;position:absolute;height:114px">
								<a href="https://renz.iptzx.com/" class="img-wrap " target="_blank">
									<img src="../assets/3.png" style="height: 100%;width: auto;">
								</a>
							</div>
							<h3 id="aab79adf2fad54b" class="text no-margin nowrap cpTitle"
								style="; left:51px;top:160px;width:158px;min-height:33px;position:absolute;height:33px">
								<div style="text-align: center; text-shadow: none;">
									<a href="https://renz.iptzx.com/" rel="noopener noreferrer" target="_blank">
										<span style="font-size: 18px;">
											<span style="color: rgb(25, 28, 61);">
												<strong>企业官媒备案确权</strong>
											</span>
										</span>
									</a>
								</div>
							</h3>
							<div id="ab877a87f1a9303" class="text no-margin cpContent"
								style="; left:17px;top:201px;width:226px;min-height:84px;position:absolute;height:84px">
								<div style="text-align: center; text-shadow: none;">
									<a href="" rel="noopener">
										<span style="font-size: 14px; color: rgb(117, 119, 139);">
											官媒备案:奠定合法基础</br>
											确权先行:明确版权归属</br>
											技术赋能:智能监控与维权</br>
											教育普及:提升全员版权意识
										</span>
									</a>
								</div>
							</div>
						</div>
					</div>

				</div>
				<div class="ap-content-container"> </div>
			</div>
			<div id="ac102a029caa269" section-id="ac102a029caa269"
				class="section uk-section uk-section-small uk-section-default uk-dark free"
				style=";height:569px;overflow:hidden;">
				<div class="free-content-wrap" style="max-width: 1200px; margin-left: -600px">
					<h2 id="afb5babee7a1c21" class="text no-margin nowrap"
						style="; left:460px;top:60px;width:280px;min-height:41px;position:absolute;height:41px">
						<div style="text-shadow: none; text-align: center;"><span
								style="font-size: 28px; color: rgb(25, 28, 61);"><strong>为什么加入我们</strong></span></div>
					</h2>
					<div id="a82fea5ff3a5ad5" class="box h-float"
						style="left:0px;top:166px;width:262px;min-height:320px;position:absolute;height:320px">
						<div class="ele-wrap normal">
							<div id="aa7faa9469a0c6b" class="image"
								style="left:43px;top:26px;width:175px;min-height:122px;position:absolute;height:122px">
								<div class="img-wrap ">
									<img src="../assets/cooperate/1608885124208.png" width="100%">
								</div>
							</div>
							<h3 id="ab45ea6dedaf809" class="text no-margin nowrap cpTitle"
								style="; left:51px;top:160px;width:158px;min-height:33px;position:absolute;height:33px">
								<div style="text-shadow: none; text-align: center;"><span
										style="font-size: 18px; color: rgb(25, 28, 61);"><strong>领先的技术</strong></span>
								</div>
							</h3>
							<div id="a82caae9bda2b3e" class="text no-margin cpContent"
								style="; left:17px;top:201px;width:226px;min-height:84px;position:absolute;height:84px">
								<div style="text-shadow: none; text-align: center;"><span
										style="font-size: 14px; color: rgb(117, 119, 139);">可实现网络内容实时存证、精准监测、取证及提供法律支持等全生命周期服务</span>
								</div>
							</div>
						</div>
					</div>
					<div id="abb20af98fad657" class="box h-float"
						style="left:312px;top:166px;width:262px;min-height:320px;position:absolute;height:320px">
						<div class="ele-wrap normal">
							<div id="a79d8ad187a2ec4" class="image"
								style="left:45px;top:26px;width:171px;min-height:114px;position:absolute;height:114px">

								<div class="img-wrap ">
									<img src="../assets/cooperate/1608885500212.png" width="100%">
								</div>

							</div>
							<h3 id="a3dcea6cf4a2d6f" class="text no-margin nowrap cpTitle"
								style="; left:51px;top:160px;width:158px;min-height:33px;position:absolute;height:33px">
								<div style="text-align: center; text-shadow: none;"><span
										style="font-size: 18px;"><strong><span
												style="color: rgb(25, 28, 61);">培训赋能</span></strong></span></div>
							</h3>
							<div id="a08e3a1f02a6160" class="text no-margin cpContent"
								style="; left:17px;top:201px;width:226px;min-height:84px;position:absolute;height:84px">
								<div style="text-align: center; text-shadow: none;">
									<span style="font-size: 14px; color: rgb(117, 119, 139);">
										采用线上线下有机融合的创新模式，为伙伴提供系统培训，全方位开展各项前沿技术培训课程，还授予权威技术认证，切实助力伙伴深度掌握 应用之道，全方位提升技术服务能力
									</span>
								</div>
							</div>
						</div>
					</div>
					<div id="af009afcfaa49db" class="box h-float"
						style="left:625px;top:166px;width:262px;min-height:320px;position:absolute;height:320px">
						<div class="ele-wrap normal">
							<div id="a3eb1a6049a5cbb" class="image"
								style="left:43px;top:26px;width:175px;min-height:105px;position:absolute;height:105px">

								<div class="img-wrap ">
									<img src="../assets/cooperate/1608885555611.png" width="100%">
								</div>

							</div>
							<h3 id="a0d07a1d14a244f" class="text no-margin nowrap cpTitle"
								style="; left:51px;top:160px;width:158px;min-height:33px;position:absolute;height:33px">
								<div style="text-align: center; text-shadow: none;"><span
										style="font-size: 18px;"><strong><span
												style="color: rgb(25, 28, 61);">市场营销</span></strong></span></div>
							</h3>
							<div id="a072ca6716a42d1" class="text no-margin cpContent"
								style="; left:17px;top:201px;width:226px;min-height:63px;position:absolute;height:63px">
								<div style="text-align: center; text-shadow: none;">
									<span style="font-size: 14px; color: rgb(117, 119, 139);">
										携手伙伴共推系列市场营销活动，含括行业沙龙、高端客户论坛、行业展会及各种推广等项目
									</span>
								</div>
							</div>
						</div>
					</div>
					<div id="a5ee0a67a4a1e19" class="box h-float"
						style="left:938px;top:166px;width:262px;min-height:320px;position:absolute;height:320px">
						<div class="ele-wrap normal">
							<div id="a4ae5a37e2a1b74" class="image"
								style="left:43px;top:27px;width:175px;min-height:111px;position:absolute;height:111px">

								<div class="img-wrap ">
									<img src="../assets/cooperate/1608885626610.png" width="100%">
								</div>

							</div>
							<h3 id="afdd0abfb5afe9b" class="text no-margin nowrap cpTitle"
								style="; left:51px;top:160px;width:158px;min-height:33px;position:absolute;height:33px">
								<div style="text-align: center; text-shadow: none;"><span
										style="font-size: 18px;"><strong><span
												style="color: rgb(25, 28, 61);">生态共赢</span></strong></span></div>
							</h3>
							<div id="aecaea4ee9a5987" class="text no-margin cpTitle"
								style="; left:17px;top:201px;width:226px;min-height:63px;position:absolute;height:63px">
								<div style="text-align: center; text-shadow: none;">
									<span style="font-size: 14px; color: rgb(117, 119, 139);">
										构建开放、协同、共赢的生态体系,助力合作伙伴快速融入极具活力的行业生态圈
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ap-content-container"> </div>
			</div>
			<section class="GXu8DdbV" ui-type="V5G025">
				<h2><strong>渠道联系方式</strong></h2>
				<div class="v5-group-base">
					<div class="_RVgYHTQ VvEJfGuf">
						<div class="sISPUjGi"><i class="cxA1_FnM"
								style="background-image:url(https://bce.bdstatic.com/portal-icons/v5g025-sprite/duozhongshilileiixng.png)"></i>
							<div class="__2iwCTxxz">
								<h3>华东大区</h3>
								<p><span>联系方式：18523965075（微信同号）</span></p>
							</div>
						</div>
						<div class="sISPUjGi"><i class="cxA1_FnM"
								style="background-image:url(https://bce.bdstatic.com/portal-icons/v5g025-sprite/bushukuaisu.png)"></i>
							<div class="__2iwCTxxz">
								<h3>华南大区</h3>
								<p><span>联系方式：19282329155</br>微信：micp-888888</span></p>
							</div>
						</div>
						<div class="sISPUjGi"><i class="cxA1_FnM"
								style="background-image:url(https://bce.bdstatic.com/portal-icons/v5g025-sprite/gaoxiaolv.png)"></i>
							<div class="__2iwCTxxz">
								<h3>西南大区</h3>
								<p><span>联系方式：17749953610（微信同号）</span></p>
							</div>
						</div>
						<div class="sISPUjGi"><i class="cxA1_FnM"
								style="background-image:url(https://bce.bdstatic.com/portal-icons/v5g025-sprite/kuozhanxingqiang.png)"></i>
							<div class="__2iwCTxxz">
								<h3>北方大区</h3>
								<p><span>联系方式：15178787381（微信同号）</span></p>
							</div>
						</div>
					</div>
				</div>
			</section>
			

			<div id="ae4aca0a59aebf1" section-id="ae4aca0a59aebf1"
				class="section uk-section uk-section-large uk-dark special" style="">
				<div class="ap-background-container uk-cover-container" style="
		                    position: absolute">
					<div class="ap-background-layer" style="background-color: undefined"></div>
					<div class="ap-background-layer"
						:style="'opacity: 1;background-position: center center;background-repeat: no-repeat;background-size: cover;background-image: url('+require('@/assets/cooperate/1587617217383.png')+');background-attachment: auto;'">
					</div>
				</div>
				<div class="free-content-wrap" style="max-width: 1200px; margin-left: -600px">
					<div id="a7076ab7e7a70e2" class="box"
						style="left:281px;top:208px;width:637px;min-height:43px;position:absolute;height:43px">
						<div class="ele-wrap normal">
							<div id="a7ba8a194ea1e22" class="text no-margin nowrap"
								style="; left:78px;top:11px;width:481px;min-height:21px;position:absolute;height:21px">
								<div style="text-shadow: none; text-align: center;"><span
										style="font-size: 14px; color: rgb(255, 255, 255);">中文.官媒合作伙伴就在您身边，为企业保驾护航！更多合作伙伴请在此处查找</span>
								</div>
							</div>
						</div>
					</div>
					<div id="a4fa4a9432ae605" class="box"
						style="left:313px;top:128px;width:571px;min-height:69px;position:absolute;height:69px">
						<div class="ele-wrap normal">
							<div id="a01a7a32f3ae493" class="text no-margin nowrap"
								style="; left:45px;top:16px;width:481px;min-height:45px;position:absolute;height:45px">
								<div style="text-shadow: none; text-align: center;"><span
										style="font-size: 30px; color: rgb(255, 255, 255);"><strong>合作伙伴查询</strong></span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ap-content-container">
					<div class="uk-container uk-text-center">

						<h3 data-item="title">合作伙伴查询</h3>
						<p data-item="subTitle" class="subTitle">中文.官媒合作伙伴就在您身边，为企业保驾护航！更多合作伙伴请在此处查找</p>

						<div class="search-wrap">
							<div class="search">
								<input type="text" name="name" class="name" v-model="hzValue"
									placeholder="请输入公司全称，不支持模糊查找">
								<span class="btn" @click="selectHz">
									<svg class="icon" viewBox="102.4 102.349 20.109 20.111">
										<path
											d="M 111.4253845214844 102.3489990234375 C 106.440299987793 102.3489990234375 102.3992767333984 106.3905792236328 102.3999786376953 111.3756637573242 C 102.3999786376953 116.3602676391602 106.440788269043 120.4010696411133 111.4253845214844 120.4010696411133 C 116.4099807739258 120.4010696411133 120.4507904052734 116.3602600097656 120.4507904052734 111.3756637573242 C 120.4521713256836 106.3902969360352 116.4107513427734 102.3483352661133 111.4253845214844 102.3490219116211 Z M 111.4253845214844 118.9569702148438 C 107.2379989624023 118.9569702148438 103.8434600830078 115.5624313354492 103.8434600830078 111.3750457763672 C 103.8434600830078 107.1876678466797 107.2379989624023 103.7931213378906 111.4253845214844 103.7931213378906 C 115.6131134033203 103.7924880981445 119.0079498291016 107.1873168945312 119.0079498291016 111.3750457763672 C 119.0079498291016 115.5627822875977 115.6131134033203 118.9576110839844 111.4253845214844 118.9576110839844 Z M 122.1839828491211 120.5984115600586 L 120.2182693481445 118.6704025268555 C 119.7806396484375 118.2446441650391 119.0836181640625 118.2446441650391 118.64599609375 118.6704025268555 C 118.4381637573242 118.8731155395508 118.3209533691406 119.1511535644531 118.3209533691406 119.4414749145508 C 118.3209533691406 119.731803894043 118.4381637573242 120.0098342895508 118.64599609375 120.2125473022461 L 120.6116790771484 122.1405487060547 C 121.0493087768555 122.5663299560547 121.7463531494141 122.5663299560547 122.1839904785156 122.1405487060547 C 122.3918228149414 121.9378433227539 122.509033203125 121.6598205566406 122.509033203125 121.3694763183594 C 122.509033203125 121.0791549682617 122.3918228149414 120.8011245727539 122.1839904785156 120.5984115600586 Z">
										</path>
									</svg>
									搜索
								</span>
							</div>

							<div class="partner-info uk-text-left">
								1212
							</div>
						</div>
					</div>


				</div>
			</div>



			<footer class="cloud-footer footer-pc-container">
				<div class="footer-pc">
					<div class="copy-right">
						<p style="text-align: center;">
							<a class="space" target="_blank" href="">© 2024 使用中文·官媒前必读</a>
							<a class="space" :href="icpUrl">{{icp}}</a>
							<a class="space" target="_blank" :href="wabUrl" rel="noreferrer">{{wab}}</a>
							<a target="_blank" href="https://admin.iptzx.com/policy/privacy.html">隐私政策</a>
						</p>
					</div>
				</div>
			</footer>
			<a-config-provider :transition-name="['fade-enter', 'fade-leave']">
				<a-modal title="查询结果" :visible="resShow" @cancel="loadCancle" :footer="null" wrapClassName="hyLoadBox" centered>
					<div class="resBox">
						<div class="resSuc" v-if="status=='1'">
							<span>确认是本公司合作伙伴</span></br>
							尊敬的用户，经系统核实，您查询的信息与本公司合作伙伴数据库匹配成功，欢迎您！作为我们信赖的合作伙伴，您可畅享专属服务，携手共赴新程。
						</div>
						<div class="resErr" v-if="status=='0'">
							<span>并非本公司合作伙伴</span></br>
							很遗憾，经身份信息查询，您目前并非本公司合作伙伴。若您有意向加入我们，探索合作共赢的机会，烦请访问官方，会有专人与您沟通洽谈，期待未来携手同行。
						</div>
					</div>
				</a-modal>
			</a-config-provider>
		</div>
	</div>
</template>

<script>
	import headerTop from '../components/headerTop.vue'
	import {
		message
	} from 'ant-design-vue'
	export default {
		data() {
			return {
				icp: '',
				icpUrl: '',
				wab: '',
				wabUrl: '',
				ysUrl: '',
				hzValue: '',
				resShow: false,
				status:''
			}
		},
		components: {
			headerTop
		},
		mounted() {
			this.icp = localStorage.getItem('icp')
			this.icpUrl = localStorage.getItem('icpUrl')
			this.wab = localStorage.getItem('wab')
			this.wabUrl = localStorage.getItem('wabUrl')
			if (localStorage.getItem('token')) {
				this.token = localStorage.getItem('token')
				this.userName = localStorage.getItem('nickname')
				this.headImg = localStorage.getItem('avatar')
			}
		},
		methods: {
			loadCancle() {
				this.resShow = false
			},
			async selectHz() {
				if (this.hzValue.trim() == '') {
					message.info('请输入公司名字查询')
					return
				}
				const res = await this.request({
					method: "post",
					url: "https://admin.iptzx.com/api/search/dealer",
					data: {
						key: this.hzValue
					}
				})
				console.log(res)
				if(res.data.data==''){
					this.status='0'
				}else{
					this.status='1'
				}
				this.resShow=true
			},
		}
	}
</script>

<style scoped>
	@import url('../style/header.css');
	@import url('../style/cooperate.css');
	@import url('../style/footer.css');

	a {
		text-decoration: none !important;
	}

	.resBox {
		text-align: left;
	}
	.resBox span
	{
		color: #000;
		font-size: 16px;
		font-weight: bold;
	}
	.hyLoadBox .ant-modal-title
	{
		text-align: center;
	}
</style>