<template>
	<div class="zsPage">
		<div class="zsBox">
			<div class="title">中文·官媒证书</div>
			<div class="imgBox">
				<div class="toOther" @click="toBoxShow=true">
					<!-- <a class="toItem" :href="gmUrl" target="_blank"></a>
					<a class="toItem" :href="ptzxUrl" target="_blank"></a>
					<a class="toItem" :href="sfUrl" target="_blank" v-if="hrefType!=0"></a> -->
				</div>
				<img :src="zhengUrl" />
			</div>
		</div>
		<!-- 跳转弹窗 -->
		<a-config-provider :transition-name="['fade-enter', 'fade-leave']">
		    <a-modal
		      :visible="toBoxShow"
		      @cancel="toCancle"
			  :footer="null"
			  wrapClassName="toListBox"
			  centered
		    >
			  <div class="toBox">
				<div class="boxLogo">
					<img src="../assets/gmLogo.png">
				</div>
				<div class="toTitle">点击直达应用</div>
				<div class="toList">
					 <div class="listItem" v-for="(item,index) in toList" :key="index" @click="navTo(item.type,item.url)">
						 <div class="listimgBox">
							 <img :src="item.icon" />
						 </div>
						 <div class="ptName">{{item.name}}</div>
					 </div>
				 </div>
			  </div>
		    </a-modal>
		</a-config-provider>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				toBoxShow:false,
				zhengUrl:'',
				sn:'',
				hrefType:'',
				sfUrl:'',
				gmUrl:'',
				ptzxUrl:'',
				toList:[],
			}
		},
		mounted() {
			console.log(this.$route.query)
			if(this.$route.query){
				this.sn=this.$route.query.sn
				this.getZheng()
			}
		},
		methods:{
			toCancle(){
				this.toBoxShow=false
			},
			async getZheng() {
			
				let that = this
				const res = await that.request({
					method: "get",
					url: "https://admin.iptzx.com/api/user/getSubscribe?sn="+this.sn,
					data: {}
				})
				console.log(res)
				
				this.toList=res.data.data.jump
				
				this.zhengUrl=res.data.data.url
				this.gmUrl=res.data.data.jump_gm
				this.ptzxUrl=res.data.data.jump_iptzx
				this.sfUrl=res.data.data.jump_url
				this.hrefType=res.data.data.jump_type
				// jump_type为1是网页,2是小程序,0表示没有网站和小程序跳转
			},
			navTo(type,url){
				window.open(url)
			}
		}
	}
</script>

<style>
	.zsBox
	{
		width: 100%;
		max-width: 600px;
		margin: auto;
	}
	.zsBox .title
	{
		text-align: center;
		font-weight: bold;
		font-size: 28px;
		padding: 30px 0;
		color: #333;
	}
	.zsBox img
	{
		width: 100%;
	}
	.zsBox .imgBox
	{
		position: relative;
	}
	.zsBox .imgBox .toOther
	{
		position: absolute;
		top: 490px;
		left: 50%;
		transform: translateX(-50%);
		width: 360px;
		height: 50px;
		background: rgba(0, 0, 0, 0.6);
		color: #fff;
		display: flex;
		opacity: 0;
		cursor: pointer;
	}
	.zsBox .imgBox .toOther>a
	{
		flex: 1;
		text-align: center;
		border-right: 1px solid #fff;
		box-sizing: border-box;
		cursor: pointer;
		display: block;
	}
	.zsBox .imgBox .toOther>a:last-child
	{
		border: none;
	}
	.listimgBox
	{
		height: 80px;
		display: flex;
		align-items: center;
	}
	@media screen and (max-width: 600px) {
		.zsPage
		{
			height: 100vh;
			display: flex;
			align-items: center;
		}
		.zsBox
		{
			width: 340px;
			margin: auto;
		}
		.zsBox .imgBox .toOther
		{
			position: absolute;
			top: 275px;
			width: 240px;
			height: 30px;
		}
		.zsBox .title
		{
			padding-top: 0;
		}
		
	}
	
	.toListBox .ant-modal-body
	{
		padding: 12px;
	}
	.toListBox .ant-modal-content
	{
		border-radius: 10px;
	}
	.boxLogo
	{
		text-align: center;
		margin-top: 20px;
	}
	.boxLogo img
	{
		width: 50px;
	}
	
	.toTitle
	{
		text-align: center;
		font-size: 18px;
		font-weight: 700;
		margin-top: 20px;
	}
	.toList
	{
		margin-top: 30px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		cursor: pointer;
	}
	.toList .listItem img
	{
		width: 80px;
	}
	.toList .ptName
	{
		text-align: center;
		font-size: 16px;
		margin-top: 10px;
		font-weight: 600;
		color: #333;
	}
</style>