<template>
	<div class="loginPage">
		<div class="pcPage" v-if="showPage=='pc'">
			<div class="main-layout-724c1">
				<div class="header-wrap">
					<router-link class="logo-wrap" to="/index">
						<img src="../assets/logo.png" />
					</router-link>
				</div>
				<div class="main-body-7d296">
					<div class="page-wrap">

						<div class="left-wrap">
							<div class="slogan-wrap">
								<div class="first-slogan">中文.官媒 一体深入产业</div>
								<div class="second-slogan">
									<div><svg viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor"
											stroke="currentColor" class="x-icon slogan-icon"
											style="width: 16px; height: 16px;">
											<title>AI应用详情查询@1x</title>
											<g id="AI应用详情查询" stroke-width="1" fill-rule="evenodd">
												<path
													d="M4.5,2 C5.88071187,2 7,3.11928813 7,4.5 L7,7 L7,7 L4.5,7 C3.11928813,7 2,5.88071187 2,4.5 C2,3.11928813 3.11928813,2 4.5,2 Z M4.5,9 L7,9 L7,9 L7,11.5 C7,12.8807119 5.88071187,14 4.5,14 C3.11928813,14 2,12.8807119 2,11.5 C2,10.1192881 3.11928813,9 4.5,9 Z M11.5,2 C12.8807119,2 14,3.11928813 14,4.5 C14,5.88071187 12.8807119,7 11.5,7 L9,7 L9,7 L9,4.5 C9,3.11928813 10.1192881,2 11.5,2 Z M9,9 L11.5,9 C12.8807119,9 14,10.1192881 14,11.5 C14,12.8807119 12.8807119,14 11.5,14 C10.1192881,14 9,12.8807119 9,11.5 L9,9 L9,9 Z"
													id="1-FL" stroke="#303540" fill="none" stroke-width="1"
													stroke-linecap="butt" stroke-linejoin="round" opacity=""
													fill-opacity="1" stroke-opacity="1"></path>
											</g>
										</svg>聚焦行业核心场景，打造行业标杆应用</div>
									<div><svg viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor"
											stroke="currentColor" class="x-icon slogan-icon"
											style="width: 16px; height: 16px;">
											<title>AI探索</title>
											<g id="AI探索" stroke-width="1" fill-rule="evenodd" stroke-linecap="round"
												stroke-linejoin="round">
												<path
													d="M8.5,1.5 C11.2614237,1.5 13.5,3.73857625 13.5,6.5 L13.5,7.5 C13.5,9.35017043 12.4950852,10.9656304 11.0012971,11.8303384 L11,14.5 L6,14.5 L6,12 L4,12 L3.99969684,9.68143848 C3.93827477,9.5549594 3.88202758,9.42550185 3.83123053,9.29334109 L2.5,8.5 L3.5,7.258 L3.5,6.5 C3.5,3.73857625 5.73857625,1.5 8.5,1.5 Z"
													id="1-FL" stroke="#303540" fill="none" stroke-width="1"
													stroke-linecap="butt" stroke-linejoin="round" opacity=""
													fill-opacity="1" stroke-opacity="1"></path>
												<polyline id="4-LW" stroke="#303540" points="6 9 7.5 5 9 9" fill="none"
													stroke-width="1" stroke-linecap="butt" stroke-linejoin="round"
													opacity="" fill-opacity="1" stroke-opacity="1"></polyline>
												<line x1="6.5" y1="7.8" x2="8.5" y2="7.8" id="5-LW" stroke="#303540"
													fill="none" stroke-width="1" stroke-linecap="butt"
													stroke-linejoin="round" opacity="" fill-opacity="1"
													stroke-opacity="1">
												</line>
												<line x1="11" y1="5" x2="11" y2="9" id="6-LW" stroke="#303540"
													fill="none" stroke-width="1" stroke-linecap="butt"
													stroke-linejoin="round" opacity="" fill-opacity="1"
													stroke-opacity="1"></line>
											</g>
										</svg>全栈自研的AI大底座，满足产业对智算基础设施的需求</div>
									<div><svg viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor"
											stroke="currentColor" class="x-icon slogan-icon"
											style="width: 16px; height: 16px;">
											<title>融合数据</title>
											<g id="融合数据" stroke-width="1" fill-rule="evenodd" stroke-linejoin="round">
												<polygon id="1-FL" stroke="#303540"
													points="8.00000002 1.5 13.5 4.75 13.5 11.25 8.00000002 14.5 2.5 11.25 2.5 4.75"
													fill="none" stroke-width="1" stroke-linecap="butt"
													stroke-linejoin="round" opacity="" fill-opacity="1"
													stroke-opacity="1">
												</polygon>
												<polyline id="2-LW" stroke="#303540"
													transform="translate(7.000000, 9.732051) rotate(-330.000000) translate(-7.000000, -9.732051) "
													points="5.5 6.23205081 8.5 6.23205081 8.5 13.2320508" fill="none"
													stroke-width="1" stroke-linecap="butt" stroke-linejoin="round"
													opacity="" fill-opacity="1" stroke-opacity="1"></polyline>
												<polyline id="3-LW" stroke="#303540"
													transform="translate(9.000000, 6.267949) rotate(-330.000000) translate(-9.000000, -6.267949) "
													points="10.5 9.76794919 7.5 9.76794919 7.5 2.76794919" fill="none"
													stroke-width="1" stroke-linecap="butt" stroke-linejoin="round"
													opacity="" fill-opacity="1" stroke-opacity="1"></polyline>
											</g>
										</svg>先进的技术和丰富的解决方案，与实体经济深度融合，共创价值</div>
								</div>
							</div>
						</div>
						<div class="right_wrap ">
							<div class="bce-login-type-tab">
								<div class="passport-tab-btn active last-login">中文.官媒账号</div>
							</div>
							<div class="bce-passport-wrap ">
								<div id="pass-login" class="tang-pass-login compose-style">
									<div id="componseRight" class="compose-right-container">
										<p id="TANGRAM__PSP_4__headerLoginTab" class="login-type-tab">
											<!-- <span id="TANGRAM__PSP_4__changeSmsCodeItem" class="switch-item activ">短信登录</span> -->
											<span id="TANGRAM__PSP_4__changePwdCodeItem"
												:class="loginType==index?'activ':''" @click="loginType=index"
												class="switch-item" v-for="(item,index) in loginItem">{{item}}</span>
										</p>
										<div id="TANGRAM__PSP_4__sms" class="tang-pass-login tang-pass-sms"
											style="display: block; visibility: visible; opacity: 1;margin-top: 36px;">
											<!-- <p class="tang-pass-sms-tip">验证即登录，未注册将自动创建账号</p> -->
											<div id="TANGRAM__PSP_4__smsForm" v-if="loginType=='0'">

												<div id="TANGRAM__PSP_4__smsPhoneWrapper"
													class="pass-form-item pass-form-item-smsPhone">
													<input id="TANGRAM__PSP_4__smsPhone" type="text" name="username"
														class="pass-text-input pass-text-input-smsPhone" v-model="phone"
														placeholder="手机号">

												</div>
												<p id="TANGRAM__PSP_4__smsVerifyCodeWrapper"
													class="pass-form-item pass-form-item-smsVerifyCode">
													<input id="TANGRAM__PSP_4__smsVerifyCode" type="text"
														name="password"
														class="pass-text-input pass-text-input-smsVerifyCode"
														maxlength="6" v-model="code" placeholder="短信验证码">

													<button id="TANGRAM__PSP_4__smsTimer" class="pass-item-timer"
														@click="sendSms">
														{{ second === totalSecond ? '发送验证码' : second + `秒后重试`}}
														<!-- 发送验证码 -->
													</button>

												</p>
												<!-- 展示错误提示信息区域 -->
												<p id="TANGRAM__PSP_4__smsErrorWrapper"
													class="pass-generalErrorWrapper">
													<span id="TANGRAM__PSP_4__smsError" class="pass-generalError">
														{{dxTip}}
													</span>
												</p>
												<span class="tang-pass-sms-agreement pass-link">
													<input name="smsIsAgree" id="TANGRAM__PSP_4__smsIsAgree"
														:checked="agree" type="checkbox"
														class="pass-checkbox-input pass-checkbox-isAgree"
														@click="agree=!agree">
													<label for="TANGRAM__PSP_4__smsIsAgree"
														style="padding-left: 5px;">我已阅读并同意</label>
													<a target="_blank" href="https://admin.iptzx.com/policy/service.html">用户协议</a>
													和
													<a target="_blank" href="https://admin.iptzx.com/policy/privacy.html">隐私政策</a>
												</span>

												<p id="TANGRAM__PSP_4__smsSubmitWrapper"
													class="pass-form-item pass-form-item-submit">
													<input id="TANGRAM__PSP_4__smsSubmit" type="submit" value="登录/注册"
														class="pass-button pass-button-submit"
														:class="!agree?'pass-button-full-disabled':''"
														:disabled="!agree" @click="login">
												</p>
												<!-- <a style="color: #2468f2;" href="https://view.officeapps.live.com/op/embed.aspx?src=https://admin.iptzx.com/specification.doc" target="_blank">中文.官媒注册规范</a> -->
											</div>
										</div>
										<div id="TANGRAM__PSP_4__form" class="pass-form pass-form-normal"
											v-if="loginType=='1'">
											<p id="TANGRAM__PSP_4__userNameWrapper"
												class="pass-form-item pass-form-item-userName" style="">
												<input id="TANGRAM__PSP_4__userName" type="text" name="userName"
													class="pass-text-input pass-text-input-userName" v-model="phone"
													autocomplete="off" value="" placeholder="手机号">
											</p>
											<p id="TANGRAM__PSP_4__passwordWrapper"
												class="pass-form-item pass-form-item-password" style="display:">
												<input id="TANGRAM__PSP_4__password" type="password" name="password"
													v-model="password" class="pass-text-input pass-text-input-password"
													autocomplete="off" value="" placeholder="密码">

											</p>

											<p id="TANGRAM__PSP_4__errorWrapper" class="pass-generalErrorWrapper"><span
													id="TANGRAM__PSP_4__error"
													class="pass-generalError pass-generalError-error"></span></p><span
												class="tang-pass-sms-agreement pass-link">
												<input name="isAgree" :checked="agree" id="TANGRAM__PSP_4__isAgree"
													type="checkbox" class="pass-checkbox-input pass-checkbox-isAgree"
													@click="agree=!agree">
												<label for="TANGRAM__PSP_4__isAgree"
													style="padding-left: 5px;">我已阅读并同意</label>
												<a target="_blank" href="https://admin.iptzx.com/policy/service.html">用户协议</a>
												和
												<a target="_blank" href="https://admin.iptzx.com/policy/privacy.html">隐私政策</a></span>

											<p id="TANGRAM__PSP_4__submitWrapper"
												class="pass-form-item pass-form-item-submit">
												<input id="TANGRAM__PSP_4__submit" type="submit" value="登录"
													class="pass-button pass-button-submit"
													:class="!agree?'pass-button-full-disabled':''" :disabled="!agree"
													@click="login">
											</p>

										</div>
										<a style="color: #2468f2;" href="https://view.officeapps.live.com/op/embed.aspx?src=https://admin.iptzx.com/specification.doc" target="_blank">中文.官媒注册规范</a>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

		

	</div>
</template>

<script>
	import { message } from 'ant-design-vue'
	export default {
		data() {
			return {
				loginItem: ['短信登录', '密码登录'],
				loginType: '0',
				phone: '',
				password: '',
				code: '',
				dxTip: '',
				agree: false,
				totalSecond: 60, // 总秒数
				second: 60, // 倒计时的秒数
				timer: null, // 定时器 id
				showPage: 'pc'
			}
		},
		mounted() {
			this.isMobile()
			if(localStorage.getItem('token')){
				// message.info('已登录')
				this.$router.push('index')
			}
		},
		methods: {
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				return flag;
			},
			async sendSms() {

				let that = this
				console.log(that.phone)
				if (that.phone.trim() == '') {
					that.dxTip = '请输入手机号'
					return
				}
				that.dxTip = ''
				const res = await that.request({
					method: "post",
					url: "https://admin.iptzx.com/api/sms/sendCode",
					data: {
						mobile: that.phone,
						scene: 'YZMDL'
					}
				})
				console.log(res)
				if (res.data.code == 1) {
					message.info('验证码发送成功')
					that.getCode()
				}
			},
			async getCode() {

				// 当前目前没有定时器开着，且 totalSecond 和 second 一致 (秒数归位) 才可以倒计时
				if (!this.timer && this.second === this.totalSecond) {
					// 开启倒计时
					this.timer = setInterval(() => {
						this.second--

						if (this.second <= 0) {
							clearInterval(this.timer)
							this.timer = null // 重置定时器 id
							this.second = this.totalSecond // 归位
						}
					}, 1000)

					// 发送请求，获取验证码
					// this.$toast('发送成功，请注意查收')
					// alert('发送成功，请注意查收')
				}
			},
			async login() {
				let that = this
				var type
				if (this.loginType == 0) {
					//验证码登录
					type = 2
					if (this.phone.trim() == '') {
						message.error('请输入手机号')
						return
					}
					if (this.code.trim() == '') {
						message.error('请输入验证码')
						return
					}

				} else if (this.loginType == 1) {
					//密码登录
					type = 1
					if (this.phone.trim() == '') {
						message.error('请输入手机号')
						return
					}
					if (this.password.trim() == '') {
						message.error('请输入密码')
						return
					}
				}
				const res = await this.request({
					method: "post",
					url: "https://admin.iptzx.com/api/login/account",
					data: {
						account: this.phone,
						terminal: '4',
						scene: type,
						password: this.password,
						code: this.code
					}
				})
				console.log(res)
				if (res.data.code == 1) {
					localStorage.setItem('avatar', res.data.data.avatar)
					localStorage.setItem('nickname', res.data.data.nickname)
					localStorage.setItem('token', res.data.data.token)
					message.success('登录成功')
					setTimeout(function(){
						// that.$router.push("/index")
						that.$router.back(0)
					},1500)
					
				}
				if (res.data.code == 0 && res.data.show == 1) {
					console.log(res.data.msg)
				}
			}

		}
	}
</script>

<style scoped>
	@import url('../style/login.css');
</style>