<template>
	<div class="qqPage">
		
		<header-top></header-top>
		
		<div class="content-wrapper" id="beian">
		    <section class="banner">
		        <div class="container">
		            <h2>备案确权中心</h2>
		            <p class="banner-desc">在中华人民共和国境内提供互联网信息服务，应当依法履行备案确权，中文·官媒提供备案确权服务</p>
		            <p class="banner-extra">客服热线：<!-- <strong>400-920-8999</strong>转<strong>3</strong>号线 --></p>
		            <div class="button-panel">
		                <!-- <a class="button-primary" href="https://beian.bce.baidu.com" target="_blank">登入ICP备案系统</a>
		                <a class="button-normal" href="https://beian.miit.gov.cn/">公共查询&amp;短信验证</a> -->
		                <!-- <a class="button-none" href="https://cloud.baidu.com/doc/tools/beian.html">各省规则查询</a> -->
		            </div>
		        </div>
		    </section>
		
		    <section class="notices">
		        <ul class="container">
		            
		            <li>
		                <a class="notice-a" href="" rel="nofollow">
		                    关于开展移动互联网应用程序备案工作的通知
		                </a>
		            </li>
		            
		            <li>
		                <a class="notice-a" href="" rel="nofollow">
		                    工业和信息化部关于进一步落实网站备案信息真实性核验工作方案（试行）
		                </a>
		            </li>
		            
		            <li>
		                <a class="notice-a" href="" rel="nofollow">
		                    《关于加强互联网站备案管理的工作方案》
		                </a>
		            </li>
		            
		        </ul>
		    </section>
		
		    <section class="process">
		        <div class="container">
		            <h3>备案确权流程</h3>
		            <!-- <img src="https://bce.bdstatic.com/bce-portal/static/assets/assets/00ca28e3.png" alt="ICP备案流程"> -->
		            <img src="../assets/00ca28e3[1](1).png" alt="备案确权流程">
		            <div class="process-a-wrapper">
		                <a class="process-a" href="">立即开始备案</a>
		            </div>
		        </div>
		    </section>
		
		    <section class="service">
		        <div class="container">
		            <h3>备案管家服务</h3>
		            <div class="service-wrapper">
		                <div class="service-process">
		                    <h4>服务流程<span>（完成后同步管局审核结果）</span></h4>
		                    <ul>
		                        
		                        <li>
		                            <h5>准备资料</h5>
		                            <p>按照管家提示，准备简单资料</p>
		                        </li>
		                        
		                        <li>
		                            <h5>提交初审</h5>
		                            <p>按照管家提示，确认您的信息</p>
		                        </li>
		                        
		                        <li>
		                            <h5>真实性核验</h5>
		                            <p>按照管家提示，完成刷脸认证</p>
		                        </li>
		                        
		                    </ul>
		                </div>
		                <div class="service-detail">
		                    <h4>服务详情</h4>
		                    <ul>
		                        
		                        <li>
		                            <p>专家辅助，协助您完成网站及APP备案申请，可根据您的实际需要选择备案数量</p>
		                        </li>
		                        
		                        <li>
		                            <p>资料预审，全程一对一管家服务，快速纠错，快速提交提高通过率</p>
		                        </li>
		                        
		                        <li>
		                            <p>免摸索免填写，管家代劳统一收集材料，专项核查全程跟进</p>
		                        </li>
		                        
		                    </ul>
		                    <div class="service-price"><span>¥</span>540<span>/件起</span></div>
		                    <div class="service-btn-wrapper">
		                        
		                        <a class="service-btn" href="" target="_blank">立即购买</a>
		                        
		                        <a class="service-btn" href="" target="_blank">了解详情</a>
		                        
		                    </div>
		                </div>
		            </div>
		        </div>
		    </section>
		
		    <section class="helps">
		        <div class="container">
		            <h3>备案确权帮助</h3>
		            <ul class="help-card-wrapper">
		                
		                <li class="help-card">
		                    <h4>官媒备案：奠定合法基础</h4>
							<div>
								首先，企业需明确，官方媒体平台的备案是保护新媒体知识产权的第一步。无论是微信公众号、微博蓝V、抖音企业号，品推资讯，还是其他各类新兴社交媒体平台，完成官方认证与备案，不仅能够提升品牌的公信力与辨识度，更重要的是，它为企业在新媒体平台上的一切活动提供了法律上的认可与保护。备案过程中，企业需提交真实有效的企业信息，确保账号的合法性与唯一性，为后续的知识产权维权打下坚实基础。
							</div>
		                    
		                </li>
		                
		                <li class="help-card">
		                    <h4>确权先行：明确版权归属</h4>
							<div>
								确权，即明确新媒体内容的版权归属，是保护知识产权的核心。企业应建立健全的内容创作与审核机制，确保每一篇图文、每一个视频、每一段音频，从构思到发布，都有明确的创作者、发布时间及版权声明。利用水印、版权标签等技术手段，对原创内容进行标记，既是对创作者劳动成果的尊重，也是向外界宣告版权的明确信号。同时，对于引用、转载的内容，务必遵循相关法律法规，注明来源，获取授权，避免侵权风险。
							</div>
		                    
		                </li>
		                
		                <li class="help-card">
		                    <h4>技术赋能：智能监控与维权</h4>
							<div>
								面对网络上海量的信息，人工监控侵权行为无疑是大海捞针。因此，借助先进的技术手段进行智能监控与维权至关重要。企业可以接入版权保护平台，利用大数据与AI技术，对全网进行实时监控，一旦发现侵权行为，立即启动预警机制，快速响应。同时，建立与第三方版权服务机构的合作关系，通过法律途径高效维权，包括但不限于发送警告信、提起诉讼等，让侵权者付出应有的代价。
							</div>
		                    
		                </li>
		                
		                <li class="help-card">
		                    <h4>教育普及：提升全员版权意识</h4>
							<div>
								最后，知识产权保护不仅仅是法务部门或个别岗位的责任，而是需要企业上下一心，共同参与。通过内部培训、案例分享、文化营造等多种方式，不断提升全体员工的版权意识与法律意识，让尊重原创、保护知识产权成为企业文化的一部分。只有当每个人都成为知识产权的守护者，企业的新媒体资产才能得到最有效的保护。
							</div>
		                    
		                </li>
		                
		            </ul>
		        </div>
		    </section>
		
		    <section class="correlations">
		        <div class="container">
		            <h3>相关产品及增值服务</h3>
		            <ul>
		                
		                <li>
		                    <a class="correlations-a" href="" target="_blank">
		                        
		                        <div class="tag-icon hot">
		                            <img src="https://bce.bdstatic.com/bce-portal/static/assets/assets/68aa6c3d.svg" alt="">
		                        </div>
		                        
		                        <h4>中文·官媒智能建站</h4>
		                        <p>中文·官媒推出的八合一建站系统，支持可视化设计，极速收录，AI智能营销</p>
		                        <div class="tags">
		                            
		                            <span>企业网站建设</span>
		                            
		                            <span>SEO功能更强</span>
		                            
		                        </div>
		                    </a>
		                </li>
		                
		                <li>
		                    <a class="correlations-a" href="" target="_blank">
		                        
		                        <h4>多端小程序开发</h4>
		                        <p>微信小程序、百度智能小程序、支付宝小程序，一次制作多端上线</p>
		                        <div class="tags">
		                            
		                            <span>小程序开发</span>
		                            
		                            <span>私域流量营销</span>
		                            
		                        </div>
		                    </a>
		                </li>
		                
		                <li>
		                    <a class="correlations-a" href="" target="_blank">
		                        
		                        <div class="tag-icon new">
		                            <img src="https://bce.bdstatic.com/bce-portal/static/assets/assets/a5d557b2.svg" alt="">
		                        </div>
		                        
		                        <h4>高端网站定制</h4>
		                        <p>中文·官媒UI/UE高级设计师，根据您的官媒形象，一对一为您定制官网</p>
		                        <div class="tags">
		                            
		                            <span>一对一服务</span>
		                            
		                            <span>无忧退款保障</span>
		                            
		                        </div>
		                    </a>
		                </li>
		                
		                <li>
		                    <a class="correlations-a" href="" target="_blank">
		                        
		                        <h4>中文·官媒证书</h4>
		                        <p>为您的网站启用中文·官媒证书，提升网站安全，去除浏览器“不安全”标识</p>
		                        <div class="tags">
		                            
		                            <span>中文·官媒证书保障</span>
		                            
		                            <span>浏览器标识</span>
		                            
		                        </div>
		                    </a>
		                </li>
		                
		            </ul>
		        </div>
		    </section>
		</div>
		<transition name="fade">
			<div class="mask" v-if="menuShow=='1'" @click="menuShow=0"></div>
		</transition>
		<!-- 底部导航 -->
		<footer class="cloud-footer footer-pc-container">
			<div class="footer-pc">
				<div class="copy-right">
					<p style="text-align: center;">
						<a class="space" target="_blank" href="">© 2024 使用中文·官媒前必读</a>
						<!-- <span class="space">增值电信业务经营许可证：B1.B2-20100266</span> -->
						<a class="space" :href="icpUrl">{{icp}}</a>
						<!-- <a class="space" target="_blank">官媒注册服务机构许可：京D3-20210001</a> -->
						<!-- <span class="space">代理官媒注册服务机构：商务中国 新网数码 江苏邦宁 三五互联</span> -->
						<a class="space" target="_blank" :href="wabUrl" rel="noreferrer">{{wab}}</a>
						<a target="_blank" href="https://admin.iptzx.com/policy/privacy.html">隐私政策</a>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	import headerTop from '../components/headerTop.vue'
	export default{
		data() {
			return{
				token:'',
				headImg: '',
				userName: '',
				icp: '',
				icpUrl: '',
				wab: '',
				wabUrl: '',
				ysUrl: '',
				loginUrl:'login',
				menuShow:'0'
				
			}
		},
		components:{
			headerTop
		},
		mounted() {
			// this.getSs('', 1)
			this.icp = localStorage.getItem('icp')
			this.icpUrl = localStorage.getItem('icpUrl')
			this.wab = localStorage.getItem('wab')
			this.wabUrl = localStorage.getItem('wabUrl')
			if (localStorage.getItem('token')) {
				this.token = localStorage.getItem('token')
				this.userName = localStorage.getItem('nickname')
				this.headImg = localStorage.getItem('avatar')
			}
			// this.isMobile()
		}
	}
</script>

<style scoped>
	/* @import url('../style/header - 副本.css'); */
	@import url('../style/header.css');
	@import url('../style/quequan.css');
	@import url('../style/footer.css');
	.userName {
		font-size: 14px !important;
		margin-right: 20px;
	}
	.mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 8;
	}
</style>