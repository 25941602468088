import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  // { path: "/", redirect: "/index" },
  {
    path: "/",
    name: "index",
    component: () => import('@/views/index')
  },
  {
    path: "/index",
    name: "index",
    component: () => import('@/views/index')
  },
  {
    path: "/login",
    name: "login",
    component: () => import('@/views/login')
  },
  {
    path: "/mLogin",
    name: "mLogin",
    component: () => import('@/views/mLogin')
  },
  {
    path: "/my",
    name: "my",
    component: () => import('@/views/my')
  },
  {
    path: "/quequan",
    name: "quequan",
    component: () => import('@/views/quequan')
  },
  {
    path: "/showZheng",
    name: "showZheng",
    component: () => import('@/views/showZheng')
  },
  {
    path: "/copyright",
    name: "copyright",
    component: () => import('@/views/copyright')
  },
  {
    path: "/cooperate",
    name: "cooperate",
    component: () => import('@/views/cooperate')
  },
  
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
