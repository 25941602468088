import axios from 'axios'
//import CancelStore from '@/axios/store';
import { message } from 'ant-design-vue';
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV === 'debug') {
    axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = ''
}
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'
// axios.defaults.headers.post['token'] = localStorage.getItem('token')
// console.log(localStorage.getItem('token'))
// let baseURL = "http://api.iptzx.com/api";
// if (process.env.NODE_ENV === "development") {
//   baseURL = process.env.baseURL;
// } else {
//   baseURL = process.env.baseURL;
// }

// axios.defaults.timeout = 30000;
// axios.defaults.retry = 1;
// axios.defaults.retryDelay = 1000;


//const CancelToken = axios.CancelToken;


// 创建axios实例
const request = axios.create({
	// headers:{
	// 	token:localStorage.getItem('token')
	// },
    timeout: 30000 // 请求超时时间
})

// request拦截器
request.interceptors.request.use(
    config => {
        return config
    },
    error => {
        console.log(error) // for debug 11
        Promise.reject(error)
    }
)

// response 拦截器
request.interceptors.response.use(
    response => {
		
		
		
        if (response.status === 200) {
			//公共提示
			if(response.data.code==0 && response.data.show==1){
				message.error({
					content: response.data.msg,
					duration: 3
				})
			}
			if(response.data.code==-1){
				message.error({
					content: response.data.msg,
					duration: 3
				})
				localStorage.clear()
				this.$router.push('/index')
				// location.href='/index'
			}
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
    },
    error => {
        message.error({
            content: error.message,
            duration: 3
        })
        return Promise.reject(error)

    }
)

export default request
