<template>
	<div>
		<!-- 头部导航 -->
		<div id="qifu-common-header_of_sdk">
			<header class="qifu-header-sdk-container">
				<div class="qifu-header-content">
					<div class="qifu-header-service-container">
						<div class="header-service-left">
							<router-link class="cloud-logo" to="/index">
								<img src="../assets/zwLogo.png">
							</router-link>
						</div>
						<div class="qifu-header-nav">
							<div class="qifu-header-nav-container">
								<div class="nav-item-container">
									<div class="nav-item-container-name">
										<router-link to="/index" class="nav-item-text nav-item-text-cursor" target="_blank">首页</router-link>
									</div>
								</div>
								<!-- <div class="nav-item-container">
									<div class="nav-item-container-name">
										<a class="nav-item-text nav-item-text-cursor" href="">注册服务</a>
									</div>
									
								</div> -->
								<!-- <div class="nav-item-container">
									<div class="nav-item-container-name">
										<a class="nav-item-text nav-item-text-cursor" href="">企业自媒体</a>
									</div>
										
								</div> -->
								<div class="nav-item-container">
									<div class="nav-item-container-name">
										<!-- <router-link to="/quequan" class="nav-item-text nav-item-text-cursor">备案确权</router-link> -->
										<a class="nav-item-text nav-item-text-cursor" target="_blank" href="https://renz.iptzx.com/">备案确权</a>
									</div>
										
								</div>
								<div class="nav-item-container">
									<div class="nav-item-container-name">
										<router-link to="/copyright" class="nav-item-text nav-item-text-cursor" target="_blank">版权保护解决方案</router-link>
									</div>
										
								</div>
								<!-- <div class="nav-item-container">
									<div class="nav-item-container-name">
										<a class="nav-item-text nav-item-text-cursor" href="">数字对象</a>
									</div>
										
								</div> -->
										
								<div class="nav-item-container">
									<div class="nav-item-container-name">
										<router-link to="/cooperate" class="nav-item-text nav-item-text-cursor" target="_blank">渠道合作</router-link>
									</div>
								</div>
								<div class="nav-active-line" style="width: 0px; left: 649px;"></div>
							</div>
						</div>
						<div class="header-service-right">
							<div class="mobMenu">
								<img src="../assets/menu.png" v-if="menuShow=='0'" @click="menuShow=1">
								<img src="../assets/close.png" v-if="menuShow=='1'" @click="menuShow=0">
							</div>
							<div class="user-container" v-if="token==''">
								<!-- loginUrl -->
								<div class="pcShow">
									<router-link class="login" id="loginContainer" to="/login">登录</router-link>
									<router-link class="register" id="registerContainer" to="/login">注册</router-link>
								</div>
								<div class="mobShow">
									<router-link class="login" id="loginContainer" to="/mLogin">登录</router-link>
									<router-link class="register" id="registerContainer" to="/mLogin">注册</router-link>
								</div>
								
							</div>
							
							<div v-else class="base-info_qS_guBw">
								<router-link to="/my">
									<!-- <div class="avatar_l4eGGBq">1</div> -->
									<div class="avatar_l4eGGBq">
										<img :src="headImg" />
									</div>
									<div class="userName">
										{{userName}}
									</div>
								</router-link>
							</div>
						</div>
					</div>
					<div class="header-over-next-line"></div>
				</div>
				<div class="menuList" v-if="menuShow=='1'">
					<div class="menuItem">
						<router-link to="/index" class="nav-item-text nav-item-text-cursor">首页</router-link>
					</div>
					<!-- <div class="menuItem">
						<a class="nav-item-text nav-item-text-cursor" href="">注册服务</a>
					</div> -->
					<!-- <div class="menuItem">
						<a class="nav-item-text nav-item-text-cursor" href="">企业自媒体</a>
					</div> -->
					<div class="menuItem">
						<a class="nav-item-text nav-item-text-cursor" target="_blank" href="https://renz.iptzx.com/">备案确权</a>
					</div>
					<div class="menuItem">
						<router-link to="/copyright" class="nav-item-text nav-item-text-cursor">版权保护解决方案</router-link>
					</div>
					<!-- <div class="menuItem">
						<a class="nav-item-text nav-item-text-cursor" href="">数字对象</a>
					</div> -->
					<div class="menuItem">
						<router-link to="/cooperate" class="nav-item-text nav-item-text-cursor">渠道合作</router-link>
					</div>
				</div>
			</header>
			<transition name="fade">
				<div class="mask" v-if="menuShow=='1'" @click="menuShow=0"></div>
			</transition>
		</div>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				menuShow:'0',
				token:'',
				userName:'',
				headImg:''
			}
		},
		mounted() {
			if (localStorage.getItem('token')) {
				this.token = localStorage.getItem('token')
				this.userName = localStorage.getItem('nickname')
				this.headImg = localStorage.getItem('avatar')
			}
		}
	}
</script>

<style scoped>
	.mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 8;
	}
</style>