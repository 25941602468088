<template>
	<div class="myPage">
		<div id="bce-content">
			<div class="header ui-v5 loaded" id="header">

				<router-link class="logo zh-logo" to="/index">
					<img src="../assets/logo.png" />
				</router-link>
				<div class="content">
					<div class="header-left">


					</div>
					<div class="header-right">

						<div class="user-info">

							<div id="user-nav">
								<div class="iam-antd-global iam-header-user-info_LMC_ZA0">
									<div class="base-info_qS_guBw">
										<div class="avatar_l4eGGBq">
											<img :src="headImg" />
											<!-- <a-image :width="200" :src="headImg"></a-image> -->
										</div>
										<div>
											<div class="displayName_YOBl6dV"><span
													class="iam-antd-typography iam-antd-typography-ellipsis iam-antd-typography-single-line iam-antd-typography-ellipsis-single-line">{{userName}}</span>
											</div>
										</div>
									</div>

								</div>
							</div>
							
						</div>
					</div>

				</div>
			</div>
			<div class="main-area ui-v5 ui-hack-v1">
				<div id="main" class="ui-v5"></div>
				<div id="react-main">
					<div class="antd-container">
						<div class="pageArea">
							<div class="idaas-right-layout-wrap idaas-right-layout-wrap-card-list">
								<div class="idaas-right-layout-header">账号管理</div>
								<div class="idaas-right-layout-content-wrap">
									<div class="idaas-right-layout-content">
										<div class="idaasCard_wrap  only-show card-list  ">
											<div class="idaasCard_header">
												<div class="idaasCard_title">基本信息</div>
											</div>
											<div class="idaasCard_body ">
												<div class="idaas-ant-spin-nested-loading">
													<div class="idaas-ant-spin-container">
														<div class="main___rxKhicV">
															<div class="left___2zRBC_t">
																<div class="avatar___10-2Ajn">
																	<img :src="headImg" />
																</div>
																<div>
																	<div class="title___2WYUp1L">{{userName}}</div>
																	<div class="subTitle___35JVUwv">
																		账号ID: 
																		<span class="idaas-ant-typography">
																			{{sn}}
																		</span>
																	</div>
																</div>
															</div>
															<div class="right___2lGWEHm">
																<div class="row___1KfURR7">
																	<div class="col___GZ2UC7v">
																		<div class="label___1R_LyuX">绑定手机</div>
																		<div class="content___2LyHrjo">{{phone}}</div>
																	</div>
																	<!-- <div class="col___GZ2UC7v">
																		<div class="label___1R_LyuX">属性</div>
																		<div class="content___2LyHrjo">个人</div>
																	</div> -->
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="idaasCard_wrap  only-show card-list  " style="min-height: 240px;">
											<div class="idaasCard_header">
												<div class="idaasCard_title"></div>
											</div>
											<div class="idaasCard_body  no-title">
												<div class="idaas-ant-spin-nested-loading">
													<div class="idaas-ant-spin-container">
														<div id="console-qualify-sdk-part">
															<div class="css-1mngama ant-app css-var-r0">
																<div>
																	<div class="ant-space css-1mngama ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small css-var-r0"
																		style="width: 100%; justify-content: space-between;">
																		<div class="ant-space-item">
																			<div
																				class="ant-space css-1mngama ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small css-var-r0">
																				<div class="ant-space-item">
																					<div class="sc-bCvmQg bJMVmw">官媒证书</div>
																				</div>
																				<!-- <div class="ant-space-item"><span
																						class="ant-tag ant-tag-success css-1mngama css-var-r0"
																						style="border-radius: 2px;">个人认证已完成</span>
																				</div> -->
																			</div>
																		</div>
																	</div>
																	<div class="sc-iLXxbI cfzwDL">
																		<div class="noData" v-if="zhengImg==''">
																			还没有官媒证书哦，去注册一个吧~~
																		</div>
																		<div class="zsData" v-if="zhengImg!=''">
																			<div class="zsName zsDet">
																				<span>中文.官媒名称：</span>
																				<span>{{zsName}}</span>
																			</div>
																			<div class="zsName zsDet">
																				<span>存证ID：</span>
																				<span>{{evId}}</span>
																			</div>
																			<div class="zsImg zsDet" style="cursor: pointer;">
																				<span>中文.官媒证书：</span>
																				<img :src="zhengImg" @click="visible=!visible" />
																			</div>
																		</div>
																		<!-- <a-button @click="visible=!visible">Add</a-button> -->
																		<!-- <a-image src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" /> -->
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>


										<div class="idaasCard_wrap  only-show card-list  ">
											<div class="idaasCard_header">
												<div class="idaasCard_title">账号退出</div>
											</div>
											<div class="idaasCard_body ">
												<div class="idaas-ant-spin-nested-loading">
													<div class="idaas-ant-spin-container">
														<div>您可以在此退出当前中文·官媒账号。账号退出成功后，将跳转至首页，重新登录后可再次进入当前页</div>
														<button	type="button"
															class="idaas-ant-btn idaas-ant-btn-primary idaas-ant-btn-dangerous"
															style="height: 26px; padding: 2px 12px; position: absolute; top: -18px; right: 16px;">
															<span @click="tc">退出</span>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<transition name="fade">
				<div v-if="sh" class="" style="position: fixed;top: 200px;left: 300px;width: 200px;height: 200px;background: blue;transition: all 0.3s ease;"></div>
			</transition>
			<a-config-provider :transition-name="['fade-enter', 'fade-leave']">
			    <a-modal
			      :visible="visible"
			      @cancel="handleCancel"
				  :footer="null"
				  wrapClassName="zsMod"
				  centered
			    >
			      <img :src="zhengImg" style="width: 100%;" @click="visible=!visible" download="image.jpg" />
				  <div class="doBox">
					  <button class="zsShare" @click="getUrl">证书分享</button>
					  <button class="zsShare" @click="downLoad(zhengImg,zsName+'·官媒')">证书下载</button>
					  <!-- <button class="zsShare" @click="download1">证书下载2</button> -->
				  </div>
			    </a-modal>
			</a-config-provider>

		</div>
	</div>
</template>

<script>
import { message, AImage } from 'ant-design-vue'
	export default {
		data() {
			return {
				headImg:'',
				userName:'',
				token:'',
				sn:'',
				evId:'',
				phone:'',
				zhengImg:'',
				zhengTo:'',
				zsName:'',
				sh:false,
				visible: false,

			}
		},
		components:{
			AImage:AImage
		},
		mounted() {
			if(!localStorage.getItem('token')){
				// message.info('请登录')
				this.$router.push('Index')
			}
			this.getInfo()
			// message.info('12121313131')
		},
		methods:{
			getUrl() {
			  //获取需要复制的文本内容。
				const text = 'https://micpbs.com/showZheng?sn='+this.sn;
			  // 创建一个临时的 textarea 元素。
				const el = document.createElement('textarea');
			  // 将需要复制的文本内容赋值给 textarea 元素。
			  el.value = text;
			  //将 textarea 元素添加到页面中。
			  document.body.appendChild(el);
			  //选中 textarea 元素中的文本。
			  el.select();
			  // 执行复制命令，将选中的文本复制到剪贴板
			  document.execCommand('copy');
			  //从页面中移除 textarea 元素。
			  document.body.removeChild(el);
			  //弹出提示框，告知用户文本已复制成功。
			  message.success('证书链接已复制');
			},
			
			downLoad(url, fileName) {
			  const x = new window.XMLHttpRequest();
			  x.open('GET', url, true);
			  x.responseType = 'blob';
			  x.onload = () => {
			    const url = window.URL.createObjectURL(x.response);
			    const a = document.createElement('a');
			    a.href = url;
			    a.download = fileName;
			    a.click();
			  };
			  x.send();
			},
			
			// download1() {
			//       var image = new Image();
			//   image.setAttribute("crossOrigin", "anonymous");
			//   var _this = this;
			//   image.onload = function () {
			// 	var canvas = document.createElement("canvas");
			// 	canvas.width = image.width;
			// 	canvas.height = image.height;
			// 	var context = canvas.getContext("2d");
			// 	context.drawImage(image, 0, 0, image.width, image.height);
			// 	var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
			// 	var a = document.createElement("a"); // 生成一个a元素
			// 	var event = new MouseEvent("click"); // 创建一个单击事件
			// 	a.download = _this.projectName || "photo"; // 设置图片名称
			// 	a.href = url; // 将生成的URL设置为a.href属性
			// 	a.dispatchEvent(event); // 触发a的单击事件
			//   };
			//   image.src = this.zhengImg;
			// },
			
			
			handleCancel(){
				this.visible=false
			},
			async getInfo(){
				
				const res = await this.request({
					method: "post",
					url: "https://admin.iptzx.com/api/user/center",
					headers:{
						token:localStorage.getItem('token')
					},
					data: {}
				})
				console.log(res)
				if(res.data.code==1){
					this.headImg=res.data.data.avatar
					this.userName=res.data.data.nickname
					this.sn=res.data.data.sn
					this.evId=res.data.data.evId
					this.phone=res.data.data.mobile
					this.zhengImg=res.data.data.certificate
					this.zsName=res.data.data.certificate_name
					this.zhengTo=res.data.data.jump
				}else{
					this.$router.push('/index')
				}
			},
			tc(){
				let that = this
				localStorage.clear()
				message.success('退出成功')
				setTimeout(function(){
					that.$router.push('/index')
				},1500)
				
			}
		}
	}
</script>

<style scoped>
	@import url('../style/my.css');
	.noData
	{
		display: block;
		text-align: center;
		padding: 60px 0;
	}
	/* 定义动画/过渡的开始和结束状态 */
	.fade-enter-active, .fade-leave-active {
	  transition: opacity .5s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active for <2.1.8 */ {
	  opacity: 0;
	}
	
	.fade-enter-active, .fade-leave-active {
	  transition: opacity 0.5s ease;
	}
	.fade-enter-from, .fade-leave-to {
	  opacity: 0;
	}
	/* .zsMod 
	{
		text-align: center;
	} */
	.zsShare
	{
		display: block;
		margin: auto;
		margin-top: 20px;
		cursor: pointer;
	}
</style>